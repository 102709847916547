
import galleryImg01 from '../../assets/images/foto1.jpeg'
import galleryImg08 from '../../assets/images/foto2.jpeg'
import galleryImg02 from '../../assets/images/foto3.jpeg'
import galleryImg06 from '../../assets/images/foto4.jpeg'
import galleryImg03 from '../../assets/images/foto5.jpeg'
import galleryImg04 from '../../assets/images/foto6.jpeg'
import galleryImg05 from '../../assets/images/foto7-1.jpeg'
import galleryImg07 from '../../assets/images/foto8-1.jpeg'

const galleryImages = [
    galleryImg01,
    galleryImg08,
    galleryImg02,
    galleryImg06,
    galleryImg04,
    galleryImg05,
    galleryImg03,
    galleryImg07
]

export default galleryImages